.App {
  /* text-align: center; */
  background-color: white;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
  box-shadow: 0 0 8px 8px white inset;
  border-radius: 50%;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #424242;
}

.App-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: #424242;
  padding: 10%;
}

.App-link {
  color: #61dafb;
}

h1 {
  color: #3d85c6
}

.sectionHeader {
  padding-top: 30px;
  font-size: calc(16px + 2vmin);
}

.subheader {
  font-size: calc(13px + 1.5vmin);
  padding-top: 20px;
  color: #3d85c6
}

.sectionContent {
  font-size: calc(10px + 1vmin);

}

.date {
  font-size: calc(8px + 0.5vmin);
}

.subheader>span {
  color: #424242;
}

table {
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 990px) {
  .techTableFullSize {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .techTableMobile {
    display: none;
  }
}

@media only screen and (max-width: 990px) {
  .techTableFullSize {
    display: none;
  }

  .techTableMobile {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  table > tr {
    vertical-align: top !important;
  }
}

.jobDivider {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
  width: 25%;
  margin-left: 37.5%;
  margin-right: 37.5%;
}